import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import PageStepAnimation from "../../../atoms/page-step-animation/PageStepAnimation";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import SectionTitle from "../../../molecules/section-title/SectionTitle";
import { Result } from "../../../organisms/result/domain/result";
import { useResult } from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { Events } from "../../../utils/analytics";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import { homePath } from "../../../utils/locale-configuration-utils";
import * as styles from "../../NoChip.module.scss";

const dog: string = require("../../../images/pages/results/uncovered/dog.svg").default;

const Page = () => {
  const result = useResult() as Result;
  const { t } = useTranslation();

  useTrackPageViewed(Events.NO_CHIP_VIEWED_BROWSER);

  return (
    <>
      {result && (
        <PageStepAnimation>
          <img className={styles.image} src={dog} alt="" width={88} height={88} />
          <SectionTitle title={t("pg_no_chip.title")} />
          <div className={styles.message}>
            {t("pg_no_chip.subtitle", { petName: result.pet_name })}
          </div>
          <Link to={homePath(result?.country)} className={styles.link}>
            {t("pg_no_chip.cta.text")}
          </Link>
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "pg-no-chip", PageId.noChip);

export const query = graphql`
  query NoChipQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
